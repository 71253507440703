import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface CardProps {
  onClick: (value: string) => void;
  value: string;
}

const StyledCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: navy;
  color: white;
  width: 100px;
  height: 150px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 2rem;
  user-select: none;
  border: 1px solid white;
`;

const Card = ({ onClick, value }: CardProps) => {
  return <StyledCard onClick={() => onClick(value)}>{value}</StyledCard>;
};

export default Card;
