import React, { InputHTMLAttributes } from 'react';
import styled from 'styled-components';

interface InputProps {
  label: string;
  id: string;
  value: string;
  onChange: (value: string) => void;
}
const InputWrapper = styled.div`
  margin-bottom: 1rem;
`;

const Label = styled.label`
  display: inline-block;
  margin-bottom: 0.5rem;
`;

const InputElement = styled.input`
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`;

const Input: React.FC<InputProps> = ({ label, id, value, onChange }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };
  return (
    <InputWrapper>
      <Label>{label}</Label>
      <InputElement id={id} type="text" value={value} onChange={handleChange} />
    </InputWrapper>
  );
};

export default Input;
