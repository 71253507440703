import React, { useState } from 'react';
import styled from 'styled-components';
import Input from '../components/Input';
import Card from '../components/Card';
import SubmitButton from '../components/SubmitButton';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import axios from 'axios';

const Estimate: React.FC = () => {
  const location = useLocation();
  
  const fiboNumbers = [0,1, 1, 2, 3, 5, 8, 13];
  const [choice, setChoice] = useState<string | null>('0');
  const [name, setName] = useState('');
  const [task, setTask] = useState('');
  
  const handleCardClick = (value: string) => {
        console.log(`Card com valor ${value} foi clicado`);
        setChoice(value);
      };


  const handleInputChange = (setter: React.Dispatch<React.SetStateAction<string>>) => 
    (value: string) => {
      setter(value);
  };


  const handleSubmit = async () => {
    const {taskId} = queryString.parse(location.search);

    try{
      const response = await axios.post(
        // 'http://localhost:3000/estimate', 
        'https://en19hc7i4a.execute-api.us-east-1.amazonaws.com/estimate', 
        {
          taskId: taskId,
          name: name,
          task: task,
          choice: choice
        }, 
        {
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*', // permitir solicitações de qualquer domínio
            'Access-Control-Allow-Credentials': 'true', // permitir cookies
          }
        }
      );
  
      // const responseData = await response.json();
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container>
      <PageTitle>Well Poker Planning</PageTitle>
      <Input label="Nome" id="name" value={name} onChange={handleInputChange(setName)} />
      <Input label="Tarefa" id="task" value={task} onChange={handleInputChange(setTask)} />
      <ContainerCards>
      {
        fiboNumbers.map((i) => 
        <Card onClick={handleCardClick} value={i.toString()}/>)
      }
      </ContainerCards>
      <ContainerChoice>
      <ChoiceTitle>Você escolheu: </ChoiceTitle>
     <ChoiceWrapper>{choice}</ChoiceWrapper>
      </ContainerChoice>
      <ContainerSubmit>

     <SubmitButton onSubmit={handleSubmit}></SubmitButton>
      </ContainerSubmit>

    </Container>
  );
}

const ContainerSubmit = styled.div`
display: flex;
flex-direction: row;
justify-content: center;
margin: 20px;
`;

const ChoiceWrapper = styled.div`
  margin-top: 2rem;
  font-size: 1.5rem;
  color: navy;
`;

const ChoiceTitle = styled.div`
  margin-top: 2rem;
  font-size: 1.5rem;
  color: navy;
`;

const ContainerChoice = styled.div`
display: flex;
flex-direction: row;
justify-content: center;
`;

const ContainerCards = styled.div`
display: flex;
flex-direction: row;
justify-content: center;
`;

const PageTitle = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-size: 1.5rem;
  color: navy;
`;

const Container = styled.div`
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%;
  
  @media (min-width: 768px) {
    width: 750px;
  }

  @media (min-width: 992px) {
    width: 970px;
  }

  @media (min-width: 1200px) {
    width: 1170px;
  }
`;

export default Estimate;