import React from 'react';
import logo from './logo.svg';
import './App.css';
import Estimate from './pages/Estimate'
import { BrowserRouter as Router, Route } from 'react-router-dom';

function App() {

  return (
    <Router>
      <Estimate></Estimate>
      {/* <Route path="/my-component/:param1/:param2" component={Estimate} /> */}
    </Router>
  )
}

export default App;
